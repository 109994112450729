/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2ZlOTgwMCIsIj9lcjwjZmZlMGIzIiwiO2VyPCNmZTdiMDB$LCIlPmBePCMxNjE1MTUiLCI~ZXI8I2I5YjliOSIsIjtlcjwjMGMwYzBjfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use 'sass:map';
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

$my-typography-config: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  $headline-2: mat.define-typography-level(72px, 72px, 300, 'Roboto', -0.0134em),
  $headline-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  $headline-4: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0em),
  $headline-5: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  $headline-6: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0em),
  $subtitle-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  $subtitle-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  $body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.013em),
  $body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.013em),
  $button: mat.define-typography-level(14px, 14px, 400, 'Roboto', 0.025em),
  $caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em)
);

// Foreground Elements

// not used for now
// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

@include mat.core();

// Theme Config

body {
  --primary-color: #fe9800;
  --primary-lighter-color: #ffe0b3;
  --primary-darker-color: #fe7b00;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
}

$mat-primary: (
  50: #fff4e5,
  100: #ffe2b8,
  200: #ffcf8a,
  300: #febc5c,
  400: #fea940,
  500: #fe9800,
  600: #e98900,
  700: #d47a00,
  800: #bf6b00,
  900: #a85a00,
  A100: #ffd6ad,
  A200: #ffb980,
  A400: #ff9c53,
  A700: #ff873a,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: white,
    A700: white
  )
);
//rgba(0, 0, 0, 0.87)
$primary-palette: mat.define-palette($mat-primary, 500, 300, A700);

body {
  --accent-color: #161515;
  --accent-lighter-color: #b9b9b9;
  --accent-darker-color: #0c0c0c;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};

  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$accent-palette: mat.define-palette(mat.$grey-palette, 200, A100, 400);
$accent-palette-dark: mat.define-palette(mat.$grey-palette, 600, A100, 800);
$warn-palette: mat.define-palette(mat.$red-palette);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette
    ),
    density: 0,
    typography: $my-typography-config
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette-dark,
      warn: $warn-palette
    ),
    density: 0,
    typography: $my-typography-config
  )
);

// Theme Init

html {
  @include mat.core-theme($dark-theme);
  @include mat.autocomplete-theme($dark-theme);
  @include mat.button-theme($dark-theme);
  @include mat.list-theme($dark-theme);
  @include mat.input-theme($dark-theme);
  @include mat.checkbox-theme($dark-theme);
  @include mat.datepicker-theme($dark-theme);
  @include mat.dialog-theme($dark-theme);
  @include mat.form-field-theme($dark-theme);
  @include mat.icon-theme($dark-theme);
  @include mat.progress-spinner-theme($dark-theme);
  @include mat.card-theme($dark-theme);
  @include mat.menu-theme($dark-theme);
  @include mat.slider-theme($dark-theme);
  @include mat.snack-bar-theme($dark-theme);
  @include mat.tabs-theme($dark-theme);
  @include mat.expansion-theme($dark-theme);
  @include mat.divider-theme($dark-theme);
  @include mat.radio-theme($dark-theme);
  @include mat.select-theme($dark-theme);
  @include mat.sidenav-theme($dark-theme);
  @include mat.tooltip-theme($dark-theme);
  @include mat.slide-toggle-theme($dark-theme);

  --clr-accent-light: #{mat.get-theme-color($dark-theme, accent, 900)};
  --clr-accent: #{mat.get-theme-color($dark-theme, accent, 800)};
  --clr-on-accent: #{mat.get-theme-color($dark-theme, accent, 100)};
  --clr-help: #d0d0d0;
  --clr-accent-transparent: rgba(30, 30, 30, 0.8);
  --clr-accent-transparent-2: rgba(10, 10, 10, 0.8);
  --clr-bg-transparent: rgba(0, 0, 0, 0.85);

  --mat-dialog-headline-padding: 0 40px 9px;
}

@media (prefers-color-scheme: light) or (prefers-color-scheme: no-preference) {
  html:not(:has(body[color-scheme='dark'])) {
    @include mat.core-color($light-theme);
    @include mat.autocomplete-color($light-theme);
    @include mat.button-color($light-theme);
    @include mat.list-color($light-theme);
    @include mat.input-color($light-theme);
    @include mat.checkbox-color($light-theme);
    @include mat.datepicker-color($light-theme);
    @include mat.dialog-color($light-theme);
    @include mat.form-field-color($light-theme);
    @include mat.icon-color($light-theme);
    @include mat.progress-spinner-color($light-theme);
    @include mat.card-color($light-theme);
    @include mat.menu-color($light-theme);
    @include mat.slider-color($light-theme);
    @include mat.snack-bar-color($light-theme);
    @include mat.tabs-color($light-theme);
    @include mat.expansion-color($light-theme);
    @include mat.divider-color($light-theme);
    @include mat.radio-color($light-theme);
    @include mat.select-color($light-theme);
    @include mat.sidenav-color($light-theme);
    @include mat.tooltip-color($light-theme);
    @include mat.slide-toggle-color($light-theme);
    --clr-accent-light: #{mat.get-theme-color($light-theme, accent, 200)};
    --clr-accent: #{mat.get-theme-color($light-theme, accent, 300)};
    --clr-on-accent: #{mat.get-theme-color($dark-theme, accent, 800)};
    --clr-help: #666;
    --clr-accent-transparent: rgba(220, 220, 220, 0.8);
    --clr-accent-transparent-2: rgba(190, 190, 190, 0.8);
    --clr-bg-transparent: rgba(255, 255, 255, 0.85);
  }
}

html:has(body[color-scheme='light']) {
  @include mat.core-color($light-theme);
  @include mat.autocomplete-color($light-theme);
  @include mat.button-color($light-theme);
  @include mat.list-color($light-theme);
  @include mat.input-color($light-theme);
  @include mat.checkbox-color($light-theme);
  @include mat.datepicker-color($light-theme);
  @include mat.dialog-color($light-theme);
  @include mat.form-field-color($light-theme);
  @include mat.icon-color($light-theme);
  @include mat.progress-spinner-color($light-theme);
  @include mat.card-color($light-theme);
  @include mat.menu-color($light-theme);
  @include mat.slider-color($light-theme);
  @include mat.snack-bar-color($light-theme);
  @include mat.tabs-color($light-theme);
  @include mat.expansion-color($light-theme);
  @include mat.divider-color($light-theme);
  @include mat.radio-color($light-theme);
  @include mat.select-color($light-theme);
  @include mat.sidenav-color($light-theme);
  @include mat.tooltip-color($light-theme);
  @include mat.slide-toggle-color($light-theme);

  --clr-accent-light: #{mat.get-theme-color($light-theme, accent, 200)};
  --clr-accent: #{mat.get-theme-color($light-theme, accent, 300)};
  --clr-on-accent: #{mat.get-theme-color($dark-theme, accent, 800)};
  --clr-help: #666;
  --clr-accent-transparent: rgba(220, 220, 220, 0.8);
  --clr-accent-transparent-2: rgba(190, 190, 190, 0.8);
  --clr-bg-transparent: rgba(255, 255, 255, 0.85);
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons,
.mat-mdc-button > .mat-icon.mat-icon,
.mat-mdc-raised-button > .mat-icon.mat-icon {
  font-size: 24px;
  width: auto;
  height: auto;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }
}
