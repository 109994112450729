@use 'sass:map';

$colors: (
  'dark-green': #00a967,
  'dark-green-hover': #03b671,
  'light-green': #67cb33,
  'light-green-hover': #509e25,
  'orange': #fe9800,
  'light-gray': #e9e9e9,
  'blue': rgb(93, 153, 241),
  'highlight': rgba(254, 152, 0, 0.288),
  'red': #f44336
);

$theme: (
  'underline-color': map.get($colors, 'dark-green'),
  'dark-green': map.get($colors, 'dark-green'),
  'dark-green-hover': map.get($colors, 'dark-green-hover'),
  'light-green': map.get($colors, 'light-green'),
  'light-green-hover': map.get($colors, 'light-green-hover'),
  'orange': map.get($colors, 'orange'),
  'light-gray': map.get($colors, 'light-gray'),
  'blue': map.get($colors, 'blue'),
  'red': map.get($colors, 'red'),
  'clr-primary': map.get($colors, 'orange'),
  'clr-primary-dark': darken(map.get($colors, 'orange'), 20%),
  //'clr-accent': map.get($colors, 'light-gray'),
  'clr-accent-dark': darken(map.get($colors, 'light-gray'), 10%),
  'clr-accent-darker': darken(map.get($colors, 'light-gray'), 20%),
  'clr-error': map.get($colors, 'red'),
  'clr-highlight': map.get($colors, 'highlight'),
  'clr-dark': #222,
  'header-height': 70px,
  'scene-top-offset': 72px,
  'clr-interface-background': var(--mat-app-background-color),
  'clr-interface-text': var(--mat-app-text-color)
);

:root {
  @each $key_name, $value in $theme {
    --#{$key_name}: #{$value};
  }
}

:root {
  --clr-primary-rgb: 254, 152, 0;
  --clr-success-rgb: 0, 169, 103;
}
