/* You can add global styles to this file, and also import other style files */
@import 'define.scss';
@import 'modal-box.scss';
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@-webkit-keyframes moving-gradient {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right bottom;
  }
}
* {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 1000px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--clr-accent-dark);
    border-radius: 1000px;
    &:hover {
      background-color: var(--clr-accent-darker);
    }
  }
}

:root {
  interpolate-size: allow-keywords;
}

html {
  /* overflow: hidden; */
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  min-height: 100%;
  color: var(--clr-interface-text, #000);
  background-color: var(--clr-interface-background, #fff);
  /* background: #303030; */
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-magnet {
  cursor: url('../images/magnet.png'), copy;
}

.cursor-magnet-minus {
  cursor: url('../images/magnet-minus.png'), auto;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialased;
  -moz-osx-font-smoothing: antialased;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

b,
strong {
  font-weight: 800;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

/* SPACER */
.spacer {
  flex: 1 1 auto;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-primary {
  color: var(--clr-primary) !important;
}

.padding-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

/*h1{font-size: 10px;}
h2{font-size: 12px;}
h3{font-size: 14px!important; border: 1px solid red;}
h4{font-size: 16px;}
h5{font-size: 18px;}
h6{font-size: 20px;}*/

.mat-expansion-panel {
  background: none;
}

.ng-animating mat-expansion-panel div.mat-expansion-panel-content {
  height: 0px;
  visibility: hidden;
}

.logo {
  text-align: left;
  align-self: left;
  padding-left: 2em;
}

.nav-list-title {
  padding: 0px 8px;
  min-height: 56px;
}

.btn-primary,
.mat-mdc-raised-button.btn-primary {
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-container-color: #000;
  font-size: 1.1rem;
  text-transform: uppercase;
  background: #000 url('../images/square.png') no-repeat left 10px center;
  background-size: auto 50%;
  padding-left: 40px;
  font-weight: 600;
  font-style: normal;
  &[disabled] {
    background-color: #888;
    cursor: not-allowed;
  }
}

.btn-secondary,
.mat-mdc-raised-button.btn-secondary {
  text-transform: none;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: inherit;
}

.style-success {
  // color: white;
  color: #f7931e;
}
.style-success .mat-mdc-snack-bar-action {
  color: #f7931e;
  //  color: green;
  // color: #f7931e;
}

.style-green {
  color: green;
}
.style-error {
  color: white;
}
.style-error .mat-mdc-snack-bar-action {
  color: hotpink;
}

.full-width {
  width: 100%;
}

body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.noborder,
.mat-mdc-card.noborder {
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0px;
}

.mt-1 {
  margin-top: 1em;
}

.mb-25 {
  margin-bottom: 0.25em;
}

.mat-drawer-backdrop {
  opacity: 0;
  display: none;
}

.mat-expansion-panel.condensed {
  .mat-expansion-panel-header,
  .mat-expansion-panel-content {
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: normal;
    font-size: 10px;
  }
  .mat-expansion-panel-header {
    font-weight: 400;
    padding: 0px 3px 0px 0px;
  }
  .mat-expansion-indicator::after {
    color: #1b1a1a;
    padding: 2px !important; /* decreasing inside space */
    border-width: 0px 1px 1px 0px !important; /* decreasing border-width */
  }
  .mat-expansion-panel-body {
    padding: 5px 0px;
    .slider-picker {
      padding: 0px !important;
      margin: 0px !important;
    }
    .slider-swatches {
      margin: 10px 0px !important;
    }
  }
}

.mat-mdc-form-field-required-marker {
  color: var(--red);
}

.mat-mdc-list-item {
  padding: 0;
}

.mat-mdc-list-item > .mdc-list-item__content {
  padding: 8px 3px;
}
.condensed .mat-mdc-list-item > .mdc-list-item__content {
  --mdc-list-list-item-label-text-line-height: normal;
  padding: 1px 3px;
}

.mat-mdc-tab-group.big {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-labels {
    display: flex;
    flex-direction: row;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    position: relative;
    flex: 1 1 100%;
    padding: 22px 5px;
    min-width: auto;
    height: auto;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label + .mat-tab-label {
    margin-left: -1px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label + .mat-tab-label::after {
    content: '';
    border-left: 1px solid #999;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
    width: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label:hover {
    color: var(--clr-primary-dark);
  }
}

.mat-mdc-tab-group.condensed {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label,
  .mat-tab-label-active {
    padding: 3px !important;
    margin: 3px !important;
    height: 20px !important;
    min-width: 20px !important;
    background: #efefef;
  }
}
.mat-mdc-form-field.condensed {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    margin: 0;
  }
}
.mat-mdc-form-field.mat-form-field-appearance-outline {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-subscript-wrapper {
    margin-top: 0;
    top: 100%;
    padding: 0 0.25em;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    border-width: 2px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    padding: 0 0 0.5em 0;
    width: auto;
  }
  input {
    font-weight: bold;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow-wrapper {
    transform: none;
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline.mat-form-field-type-file-input .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-type-file-input {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-outline-end,
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap {
    border-width: 1px !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-form-field-appearance-legacy .mat-form-field-infix {
  width: var(--input-width, auto);
}

#chat-application {
  right: unset !important;
  left: 24px !important;
}

.checkbox-list-condensed {
  .mat-mdc-checkbox {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
      margin-right: 3px;
    }
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-mdc-slider .mat-slider-wrapper {
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  .mat-slider-thumb-container {
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-thumb,
    .mat-slider-thumb-label {
      background-color: var(--clr-primary) !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-thumb-label-text {
      color: #fefefe;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-track-fill {
      background-color: var(--clr-primary) !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  .mat-slider-track-wrapper {
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-track-background {
      background-color: #ffefe1 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-track-fill {
      background-color: var(--clr-primary) !important;
    }
  }
}
.mat-mdc-dialog-container {
  position: relative !important;
}

/** labels **/
.info-box {
  font-size: 10px;
  font-weight: 200;
  z-index: 1000;
  text-align: center;
  color: #312fbf;
  width: 200px;
  padding-left: 100px;
}

.info-box-content {
  font-size: 10px;
  width: 100px;
  padding: 5px;
  border: 1px dashed #2a2941;
  border-radius: 5px;
}

.axle-box-content {
  font-size: 12px;
  width: 70px;
  padding: 2px;
  border: 1px dashed #2a2941;
  border-radius: 5px;
}

.scalable-label {
  --fs: 8px;
  --ff: inherit;
  line-height: 1;
  font-size: calc(var(--fs) + var(--label-fs-add, 0px));
  font-family: var(--ff);
  font-weight: 200;
  z-index: 2;
  text-align: center;
}

.cuboid-label {
  --fs: 14px;
  transform-origin: center center;
  &.scale-2_0 {
    transform: scale(2);
  }
  &.scale-3_0 {
    transform: scale(3);
  }
  &.scale-4_0 {
    transform: scale(4);
  }
}

.blue {
  color: #312fbf;
}

.color-enabled {
  color: #00c853;
}

.color-disabled {
  color: #dd2c00;
}

.material-label {
  --ff: MaterialDesign;
  --fs: 20px;
}
.label-canvas {
  position: absolute;
  background: transparent;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  color: black;
}

/** /labels **/

@media screen and (max-width: 1366px) {
  html,
  body {
    font-size: 14px;
  }
  .mat-mdc-list-base .mat-mdc-list-item {
    font-size: 14px;
    .mat-line:nth-child(n + 2) {
      font-size: 12px;
    }
  }

  .mat-mdc-card-title {
    font-size: 20px;
  }
  .mat-mdc-list-base .mat-mdc-list-item.mat-3-line,
  .mat-nav-list.mat-list-base .mat-list-item.mat-3-line,
  .mat-list-base .mat-list-option.mat-3-line {
    height: 56px;
  }

  .mat-mdc-list.mat-mdc-list-base .mat-mdc-list-item .mat-list-item-content,
  .mat-list.mat-list-base .mat-list-option .mat-list-item-content {
    padding: 6px 3px;
  }
  .mat-mdc-list-base .mat-mdc-list-item .mat-list-item-content,
  .mat-nav-list.mat-list-base .mat-list-item .mat-list-item-content,
  .mat-list-base .mat-list-option .mat-list-item-content {
    padding: 0 8px;
  }
}

.css2drenderer {
  z-index: 1;
}

.block-picker {
  box-shadow: none !important;
  .block-head {
    display: var(--preview-display, flex) !important;
    border-radius: 6px !important;
    height: var(--height, 50px) !important;
  }
  .block-label {
    display: var(--label-display, none) !important;
  }
  color-editable-input {
    display: var(--input-display, none) !important;
  }
}

.marker-mode,
.marker-mode * {
  cursor: none !important;
}

.marker-cursor {
  position: fixed;
  display: none;
  top: -10000px;
}
.marker-mode .marker-cursor,
.marker-point {
  display: block;
  transform: translate(-50%, -50%);
  z-index: 999999;
  pointer-events: none;
}

.marker-cursor,
.markers-toolbox .marker-tool,
.marker-point {
  border-radius: 50%;
  border: 1px solid black;
  width: 12px;
  height: 12px;
  &.yellow {
    background-color: yellow;
  }
  &.green {
    background-color: green;
  }
  &.skyblue {
    background-color: skyblue;
  }
  &.red {
    background-color: red;
  }
}
.markers-toolbox .marker-tool.selected {
  outline: 2px solid black;
}

.markers-toolbox {
  display: inline-flex;
  gap: 10px;
  margin-left: 20px;
}

.marker-point {
  position: absolute;
  pointer-events: auto;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* po aktualizacji na nowy material, sass  */
:root {
  --mat-form-field-container-height: 32px;
  --mat-form-field-container-vertical-padding: 0px;
  --mat-form-field-filled-with-label-container-padding-top: 10px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
  --mdc-filled-text-field-container-color: transparent;
}
.mat-mdc-card-subtitle,
.mat-mdc-card-content {
  font-size: 14px;
}

.logo-input .mat-mdc-form-field-subscript-wrapper {
  height: 0;
}

.load {
  --mat-form-field-container-height: 26px;
  --mat-form-field-container-vertical-padding: 0;
  .mat-mdc-text-field-wrapper {
    padding-right: 0;
    padding-left: 10px;
    line-height: var(--mat-form-field-container-height);
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-bottom-align::before {
    height: 0;
  }
}

.flow > * + * {
  margin-top: var(--gap, 5px);
}
.mat-expansion-panel {
  margin-top: var(--gap, 5px) !important;
}

.form-control-fill.bordered {
  --_border-color: var(--clr-accent-dark);
  border: 2px solid var(--_border-color);
  border-radius: 0.35em;
  &:focus-within {
    --_border-color: var(--clr-primary);
  }
  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    display: none;
  }
}
