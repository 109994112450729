.modal-box {
  display: flex; // make us of Flexbox

  text-align: center; // optional, but helps horizontally center text that breaks into multiple lines
  justify-content: center; // horizontally centers single line items
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  pointer-events: none;

  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #333333;
    opacity: 0.8;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    pointer-events: all;
  }

  .modal-box__container {
    margin: auto;
    border: 1px solid var(--clr-accent);
    background: var(--clr-interface-background);
    z-index: 999;
    //box-shadow: 5px 5px 5px #efefef;
    box-shadow:
      inset 0 0 0.5em var(--clr-accent),
      0.1em 0.1em 0.5em #333333;
    padding: 15px 25px;
    border-radius: 5px;
    pointer-events: all;
    .modal-box__header {
      margin-bottom: 10px;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      align-items: center;
      position: relative;
      border-bottom: 1px solid #333333;
      h1 {
        font-size: 1.3em;
      }
      &.border-bottom {
        border-bottom: 1px solid #333333;
        margin-bottom: 7px;
      }
      button {
        cursor: pointer;
        align-self: flex-end !important;

        position: absolute;
        top: -10px;
        right: -20px;

        &.close {
          background: var(--clr-interface-background);
          color: var(--clr-interface-text);
          border: 0px;
          margin: 0px;
          padding: 0px;

          mat-icon {
            margin: 0px;
            padding: 0px;
            width: auto;
            height: auto;
            font-size: 1.5em;
          }
        }
      }
    }
    .modal-box__content {
      font-size: 12px;
      ul {
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        li {
          padding: 0px;
          margin: 0px;
          button {
            border: none;
            background: var(--clr-interface-background);
            width: 100%;
            cursor: pointer;
            line-height: 20px;
            margin: 0px;
            border: 1px solid var(--clr-accent);
            &:hover {
              background: var(--clr-accent);
            }
          }
        }
      }
    }
  }
}
